  .rip {
      height: 20px;
     
      background-image: url("/rip2.png");
      background-size: 10px 2px;
      background-repeat: repeat-x;
      background-position: center;
      position: relative;
      /* box-shadow: 0 1px 0 0 #fff, 0 -1px 0 0 #fff; */
  }

  .rip:before, .rip:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      border: 14px solid transparent;
      border-top-color: #fff;
      border-right-color: #fff;
      border-radius: 100%;
      pointer-events: none;
  }

  .rip:before {
      left: -18px;
  }

  .rip:after {
      transform: translate(-50%, -50%) rotate(225deg);
      right: -45px;
  }